import {LocalData} from "../Dto/LocalData";

export default class LocalDataHandler {

    static loadLocalData(): LocalData {
        let data = "";
        if(process.env.REACT_APP_BROWSER_LOCALSTORE_NAME !== undefined){
            data = process.env.REACT_APP_BROWSER_LOCALSTORE_NAME;
        }
        let tmpData = localStorage.getItem(data);
        if(tmpData!==null){
            return JSON.parse(tmpData);
        }else{
            window.location.href = "/"
            return {
                eventId : "",
                orgId : "",

            };
        }

    }

    static clearLocalData(){
        localStorage.clear();
    }


    static writeLocalData(data:LocalData){
        // @ts-ignore
        localStorage.setItem(process.env.REACT_APP_BROWSER_LOCALSTORE_NAME, JSON.stringify(data))
    }
}
