import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import ApiClientFactory from "../modules/event/models/ApiClientFactory";
import {LocalData} from "../Dto/LocalData";
import LocalDataHandler from "../helper/LocalDataHandler";
import {Organization} from "../modules/event/models/Models";
import UserProfileHandler from "../modules/common/UserProfileHandler";
import {UserData} from "../modules/core/models/Models";

class AdminSection extends Component<AdminSectionProps, AdminSectionState>{

    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: AdminSectionProps) {
        super(props);
        this.state = {
            organisations : [],
            localData: undefined,
            userData : undefined,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData(){
        var localData: LocalData = LocalDataHandler.loadLocalData();
        const organisation = await this.apiClient.api.eventsOrganizationsDetail(localData.eventId)
        if(organisation.ok){
            this.setState(
                {
                    organisations : organisation.data,
                    localData: localData,
                    userData : await UserProfileHandler.GetUserData()
                }
            )
        }
    }

    checkIsAdmin():boolean{
        const result = this.state.userData?.claims?.find(claims => claims.value === "WinfireRegisterAdministrator")
        if(result !== undefined){
            return true
        }
        return false;
    }

    setLocalData(organisationNr:string){
        const data: LocalData = {
            orgId : organisationNr,
            eventId : this.state.localData?.eventId ?? ""
    }
        LocalDataHandler.writeLocalData(data)
        window.location.reload();
    }

    render() {
        if(!this.checkIsAdmin()){
            return <div></div>;
        }
        return <>
            <div className={"row "}>
                <div className={"col-12 text-start py-2 "}><h2>Admin</h2></div>
            </div>
            <div className={"row shadow-lg"}>
                <div className={"col-12 text-start py-3"}>
                    <table className={"table table-striped"}>
                        <tbody>
                        <tr>
                            <td>Verein</td>
                            <td><select className={"form-select"} onChange={event => {
                                this.setLocalData(event.target.value)
                            }}>
                                {this.state.organisations.sort((a,b) => a.name?.localeCompare(b.name ?? "") as number).map(org => {
                                    if(this.state.localData?.orgId.localeCompare(org.number??"")===0){
                                        return <option key={org.number} value={org.number ?? ""} selected={true}>{org.name} | {org.city}</option>
                                    }
                                    return <option key={org.number} value={org.number ?? ""}>{org.name} | {org.city}</option>
                                })}
                            </select></td>
                        </tr>
                        </tbody>
                    </table>
                    {/*<a href={"/overview"} className={"btn btn-primary"}>zur Übersicht</a> */}
                </div>

            </div>
        </>;
    }
}

interface AdminSectionState {
    organisations : Organization[]
    localData : LocalData | undefined
    userData : UserData | undefined
}

interface AdminSectionProps {

}

export default withTranslation()(AdminSection);