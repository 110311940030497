import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import NewGroupModal from "../component/newGroupModal";
import ApiClientFactory from "../modules/event/models/ApiClientFactory";
import {LocalData} from "../Dto/LocalData";
import {
    EventInfo,
    EventRegistratioInformation, GroupParticipant,
    GroupWithParticipants, Participant,
    Person,
    PersonSearch, RegisterParticipant, ShootingProgramDefinition, Weapon
} from "../modules/event/models/Models";
import LocalDataHandler from "../helper/LocalDataHandler";
import AdminSection from "../component/adminSection";

class Registration extends Component<RegistrationProps, RegistrationState> {

    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: RegistrationProps) {
        super(props);
        this.state = {
            localData: {
                orgId: "",
                eventId: ""
            },
            eventRegistrationInfos: {},
            eventInfos: {},
            people: [],
            search: "",
            orgGroups: []
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        var localData: LocalData = LocalDataHandler.loadLocalData();
        this.setState({localData: localData});
        this.loadData(localData);
    }

    async loadData(localData: LocalData) {
        try {

            var peopleSearch: PersonSearch = {
                birthday: null,
                organization: {
                    number: localData.orgId,
                    city: "",
                    name: ""
                }
            }
            var people = await this.apiClient.api.eventsPeopleSearchCreate(localData.eventId, peopleSearch);
            if (people.ok) {
                this.setState({people: people.data})
            }
            var orgGroups = await this.apiClient.api.eventsGroupsOrganisationDetail(localData.orgId, localData.eventId)
            if (orgGroups.ok) {
                this.setState({orgGroups: orgGroups.data})
            }
            var eventInfos = await this.apiClient.api.eventsEventInformationDetail(localData.eventId);
            if (eventInfos.ok) {
                this.setState({eventInfos: eventInfos.data})
            }

            var eventregistrationInfos = await this.apiClient.api.eventsEventInformationRegistrationinformationDetail(localData.eventId);
            if (eventregistrationInfos.ok) {
                this.setState({eventRegistrationInfos: eventregistrationInfos.data})
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({search: event.target.value.toLowerCase()});
    }

    getTotalGroupExerciseCount(): number {
        return this.state.eventRegistrationInfos.disciplines?.reduce((acc, discipline) => {
            const groupExerciseCount = discipline.shootingProgramDefinitions?.filter(sd => sd.isGroupExercise).length || 0;
            return acc + groupExerciseCount;
        }, 0) || 0; // Fallback zu 0, falls disciplines undefined ist
    }

    getGroupExercises() {
        const groupExercises = this.state.eventRegistrationInfos.disciplines?.flatMap(discipline =>
            discipline.shootingProgramDefinitions?.filter(sd => sd.isGroupExercise) || []
        ) || [];

        return groupExercises;
    }

    weaponSelect(spdId: number | undefined, person: Person) {
        // @ts-ignore
        let selectElement = [];
        let weapons : Weapon[] = new Array<Weapon>()
        selectElement.push(<option key={"-pl-" + person.licenseNumber+"-empty"} value={"empty"}>Waffe wählen</option>)
        // @ts-ignore
        const fields = this.state.eventRegistrationInfos.disciplines?.find(d => d.shootingProgramDefinitions?.find(spd => spd.id === spdId)).fields.map(f => {
            f?.weapons?.sort((a, b) =>
            {
                const shortNameA = a.shortName ?? '';
                const shortNameB = b.shortName ?? '';
                return shortNameA.localeCompare(shortNameB);
            }).map(w => {
                if(!weapons?.find(test => test.id === w.id)){
                    weapons?.push(w)
                    selectElement.push(<option
                        key={"f-" + f.id + "w-" + w.id + "-pl-" + person.licenseNumber}
                        value={w.id}>{w.shortName}</option>)
                }

            })

        })

        var isParticipant = false;
        this.state.orgGroups.map(orgGroup => {
            if (orgGroup.shootingProgramDefinitionId === spdId) {
                var participant: GroupParticipant | undefined = orgGroup.participants?.find(p => p.person?.licenseNumber === person.licenseNumber)
                if(participant) {
                    isParticipant = true;
                }

            }})
        if (isParticipant) {
            return <div></div>
        }else{
            // @ts-ignore
            return <select id={"spd-"+spdId+"weapon-"+person.licenseNumber} className="">{selectElement}</select>;
        }
    }


    async deleteGroup(groupeId: number, groupName: string) {
        try {
            var result = await this.apiClient.api.eventsGroupsDelete(groupeId, this.state.localData.eventId)
            if (result.ok) {
                alert("Gruppe : " + groupName + " gelöscht")
                window.location.reload();
            }
        } catch (e) {

        }
    }

    getPriceList(shootingDefinitionId:number){
        const shootingProgramDefinition: ShootingProgramDefinition | undefined = this.state.eventRegistrationInfos.disciplines?.find(discipline =>
            discipline.shootingProgramDefinitions?.find(spd => spd.id === shootingDefinitionId)
        );
        return shootingProgramDefinition?.shootingProgramDefinitionPrices
    }


    async updateParticipant(groupId: number | undefined, licenseNr: string | undefined | null, isDelete: boolean, shootingDefinitionId: number | undefined, participantId: number | undefined,) {
        try {
            if (!isDelete) {
                if((document.getElementById("spd-"+shootingDefinitionId+"weapon-"+licenseNr) as HTMLInputElement).value === "empty"){
                    alert("Bitte Waffe auswählen")
                }else{
                    var participant: RegisterParticipant;
                    participant = {
                        participantNumber: "",
                        shootingProgramDefinitionRegistrations: [{
                            shootingProgramDefinitionId: shootingDefinitionId,
                            isOrdered: true,
                            weaponId: parseInt((document.getElementById("spd-"+shootingDefinitionId+"weapon-"+licenseNr) as HTMLInputElement).value),
                            price: 0,
                            passenCount: 1
                        }],
                        person: this.state.people.find(p => p.licenseNumber === licenseNr),
                        organization: this.state.people.find(p => p.licenseNumber === licenseNr)?.organizations?.find(org => org.number === this.state.localData.orgId),
                    };
                    var checkParticipan
                    try{
                        checkParticipan = await this.apiClient.api.eventsParticipantsByLicenseDetail(licenseNr as string, this.state.localData.eventId)
                    }catch(ex){
                    }

                    var participantRes
                    if (checkParticipan) {
                        participantRes = checkParticipan.data
                        const participantTest = participantRes.shootingProgramDefinitionRegistrations?.find(spd => spd.shootingProgramDefinitionId === shootingDefinitionId);
                        if(participantTest === undefined){
                            const tmpSpdList = participantRes.shootingProgramDefinitionRegistrations?.concat(participant.shootingProgramDefinitionRegistrations ?? []);
                            participantRes.shootingProgramDefinitionRegistrations = tmpSpdList
                            var updated = await this.apiClient.api.eventsParticipantsCreate(this.state.localData.eventId, participantRes);
                            if (updated.ok) {
                                participantRes = updated.data as Participant
                            }
                        }
                    } else {
                        var created = await this.apiClient.api.eventsParticipantsCreate(this.state.localData.eventId, participant);
                        if (created.ok) {
                            participantRes = created.data as Participant
                        }
                    }
                    if (participantRes) {
                        await this.apiClient.api.eventsGroupsUpdate2(groupId as number, participantRes.participantId as number, this.state.localData.eventId)
                    }
                }

            } else {
                var deleted = await this.apiClient.api.eventsGroupsDelete2(groupId as number, participantId as number, this.state.localData.eventId)
                if (deleted.ok) {
                    var checkParticipan
                    try{
                        checkParticipan = await this.apiClient.api.eventsParticipantsByLicenseDetail(licenseNr as string, this.state.localData.eventId)
                    }catch(ex){
                    }
                    if(checkParticipan){
                        const cleanedSpdList = checkParticipan.data.shootingProgramDefinitionRegistrations?.map(spd => {
                            if(spd.shootingProgramDefinitionId === shootingDefinitionId){
                                spd.isOrdered = false;
                                return spd
                            }else{
                                return spd
                            }
                        })
                        checkParticipan.data.shootingProgramDefinitionRegistrations = cleanedSpdList
                        var update = await this.apiClient.api.eventsParticipantsCreate(this.state.localData.eventId, checkParticipan.data);
                        if (update.ok) {
                            alert("Teilnehmer wurde entfernt")
                        }
                    }

                }

            }
            this.loadData(this.state.localData)
        } catch (e) {

        }
    }

    isParticipantAllowed(licenseNr: string, groupId: number, disciplineId: number) {
        var result = this.state.orgGroups.find(g => g.participants?.find(p => p.person?.licenseNumber === licenseNr))
        if (result && result.shootingProgramDefinitionId === disciplineId) {
            return result?.id === groupId;
        } else {
            return true
        }
    }

    render() {
        return (<div>
                <div className="container-md px-5 gx-5 shadow-lg mt-lg-5">
                    <div className="row py-3">
                        <AdminSection/>
                        <div className={"row "}>
                            <div className={"col-12 text-start py-2 "}><h2>Anmeldung</h2></div>
                        </div>
                        <div className={"row shadow-lg"}>
                            <div className={"col-12 text-start py-3"}>
                                <h3>{this.state.eventInfos.name}</h3>

                                <table className={"table table-striped"}>
                                    <tbody>
                                    <tr>
                                        <td>Organisator</td>
                                        <td>{this.state.eventInfos.eventOrganizer}</td>
                                    </tr>
                                    <tr>
                                        <td>Anzahl Gruppenstiche</td>
                                        <td>{this.getTotalGroupExerciseCount()}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                {/*<a href={"/overview"} className={"btn btn-primary"}>zur Übersicht</a> */}
                            </div>

                        </div>
                        <div className={"shadow-lg mt-lg-5"}>
                            <ul className="nav nav-tabs" role="tablist" id={"exTab"}>
                                {this.getGroupExercises().map((groupExcercicse, index) => {

                                    return <li key={"k-tabs-" + groupExcercicse.id} className="nav-item"
                                               role="presentation">
                                        <button
                                            className={`nav-link ${index === 0 ? "active" : ""}`}
                                            id={"tabs-" + groupExcercicse.id}
                                            data-bs-toggle="tab"
                                            data-bs-target={"#content-" + groupExcercicse.id}
                                            type="button" role="tab"
                                            aria-controls={"content-" + groupExcercicse.id}
                                            aria-selected={index === 0 ? "true" : "false"}>
                                            <h3>{groupExcercicse.name}</h3></button>
                                    </li>
                                })
                                }
                            </ul>
                            <div className="tab-content" id="content-tabContent">
                                {this.getGroupExercises().map((groupExcercicse, index) => {
                                    return <div
                                        key={"k-tabs-content" + groupExcercicse.id}
                                        className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
                                        id={"content-" + groupExcercicse.id}
                                        role="tabpanel"
                                        aria-labelledby={"tabs-" + groupExcercicse.id}>

                                        <div key={groupExcercicse.name} className={"row "}>
                                            <div className="col-12 py-3">
                                                <div className={"row py-3"}>
                                                    <div
                                                        className={"col-8 text-start"}>
                                                        <table className={"table table-striped"}>
                                                            <tbody>
                                                            <tr>
                                                                <th className={"text-start"}>Beschreibung :</th>
                                                                <td className={"text-start"}>{groupExcercicse.description}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className={"text-start"}>Max. Gruppengrösse :</th>
                                                                <td className={"text-start"}>{groupExcercicse.groupSize}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className={"col-4 text-end"}>  <NewGroupModal updateObject={undefined} eventRegistrationInfos={this.state.eventRegistrationInfos} people={this.state.people} orgGroups={this.state.orgGroups} shootingProgrammDefinitionId={groupExcercicse.id as number} shootingProgrammDefinitions={this.getGroupExercises()}/>
                                                    </div>
                                                </div>

                                                <table className={"table table-sm table-hover table-striped"}>
                                                    <thead>
                                                    <tr>
                                                        <th className={"text-start col-2"}><i
                                                            className="bi bi-file-person"/> Schütze <input
                                                            placeholder={"suchen"} onChange={this.handleChange}/></th>
                                                        <th className={"text-center col-1"}>
                                                           Jahrgang {/*<!-- <i className="bi bi-crosshair"/> Waffen -->*/ }
                                                        </th>
                                                        <th className={"text-start col-1"}>
                                                            { /*Preis */}
                                                        </th>
                                                        {this.state.orgGroups.map(orgGroup => {
                                                            if (orgGroup.shootingProgramDefinitionId === groupExcercicse.id) {
                                                                return <th
                                                                    key={"og-" + orgGroup.id + "-" + orgGroup.groupNumber}
                                                                    className={"text-center col-1"}><i
                                                                    className="bi bi-people-fill"/> {orgGroup.groupName}<br/>
                                                                    <button
                                                                        onClick={() => this.deleteGroup(orgGroup.id as number, orgGroup.groupName as string)}
                                                                        className={'btn btn-danger'}>
                                                                        <i className="bi bi-trash-fill"/>
                                                                    </button>
                                                                    <NewGroupModal updateObject={orgGroup.id as number} eventRegistrationInfos={this.state.eventRegistrationInfos} people={this.state.people} orgGroups={this.state.orgGroups} shootingProgrammDefinitionId={groupExcercicse.id as number} shootingProgrammDefinitions={this.getGroupExercises()}/>

                                                                </th>
                                                            }


                                                        })}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.people
                                                        .filter(p => p.lastName?.toLowerCase().includes(this.state.search) || p.firstName?.toLowerCase().includes(this.state.search))
                                                        .sort((a, b) => {
                                                            if (a.lastName == null || b.lastName == null) {
                                                                return 0;
                                                            }
                                                            return a.lastName.localeCompare(b.lastName);
                                                        }).map(person => {
                                                            return <tr key={person.id}>
                                                                <td className={"text-start "}>{ person.lastName + " " + person.firstName }</td>
                                                                <td className={"text-center "}>{ person.birthday?.split("-")[0] }</td>
                                                                <td className={"text-start "}>
                                                                    { this.weaponSelect(groupExcercicse.id, person) }
                                                                </td>
                                                                {this.state.orgGroups.map(orgGroup => {
                                                                    if (orgGroup.shootingProgramDefinitionId === groupExcercicse.id) {
                                                                        var participant: GroupParticipant | undefined = orgGroup.participants?.find(p => p.person?.licenseNumber === person.licenseNumber)
                                                                        if (participant) {
                                                                            return <td
                                                                                key={"g-" + orgGroup.id + "-p-" + person.licenseNumber}>
                                                                                <input
                                                                                    value={"g-" + orgGroup.id + "-p-" + person.licenseNumber}
                                                                                    checked={true} type={"checkbox"}
                                                                                    onChange={() => this.updateParticipant(orgGroup.id, person.licenseNumber, true, orgGroup.shootingProgramDefinitionId, participant?.participantId)}/>
                                                                            </td>
                                                                        } else {
                                                                            if (groupExcercicse.groupSize !== undefined
                                                                                && orgGroup.participants?.length !== undefined
                                                                                && groupExcercicse.groupSize > orgGroup.participants?.length
                                                                                && this.isParticipantAllowed(person.licenseNumber as string, orgGroup.id as number, groupExcercicse.id as number)) {
                                                                                return <td
                                                                                    key={"g-" + orgGroup.id + "-p-" + person.licenseNumber}>
                                                                                    <input
                                                                                        value={"g-" + orgGroup.id + "-p-" + person.licenseNumber}
                                                                                        type={"checkbox"}
                                                                                        checked={false}
                                                                                        onChange={() => this.updateParticipant(orgGroup.id, person.licenseNumber, false, orgGroup.shootingProgramDefinitionId, undefined)}
                                                                                    /></td>
                                                                            } else {
                                                                                return <td
                                                                                    key={"g-" + orgGroup.id + "-p-" + person.licenseNumber}>
                                                                                    <input type={"checkbox"}
                                                                                           checked={false}
                                                                                           disabled={true}/>
                                                                                </td>
                                                                            }
                                                                        }


                                                                    }
                                                                })}
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

interface RegistrationState {
    localData: LocalData;
    eventRegistrationInfos: EventRegistratioInformation;
    eventInfos: EventInfo;
    people: Person[];
    orgGroups: GroupWithParticipants[];
    search: string;
}

interface RegistrationProps {

}

export default withTranslation()(Registration)