import {Component} from "react";
import {UserData} from "../core/models/Models";
import LocalDataHandler from "../../helper/LocalDataHandler";

export default  class Logout extends Component{

    private static userData: UserData | undefined = undefined;

    componentDidMount() {
    }

    clean() {
        LocalDataHandler.clearLocalData();
        window.location.href = `${process.env.REACT_APP_API_LOCATION}/auth/login-callback?logout=${process.env.REACT_APP_API_LOCATION}/api/public/core/logout`;
    }

    render(){
        return <div>
            <a className={"btn btn-primary"}
               onClick={event => this.clean()}>Logout</a>
        </div>
    }
}